<template>
  <div class="container">
    <!-- 订单 -->
    <div class="container_order">
      <div class="container_order_top">
        <div class="container_order_top_text">My Orders</div>
        <div
          class="container_order_top_buttons"
        >
          <div
            v-for="item in buttonList"
            :key="item.id"
            :class="[item.active === true ? 'container_order_top_buttons_item1' : 'container_order_top_buttons_item2']"
            @click="getOrderList(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div
        v-if="isEmpty"
        class="container_order_empty"
      >
        <img
          src="../../assets/images/home/noOrders.png"
          alt=""
        >
      </div>
      <div
        v-if="!isEmpty && orderIdCheck === 1"
        class="container_order_content"
      >
        <div
          class="container_order_content_item"
          v-for="demand in demandList"
          :key="demand.requirementId"
          @click="toRequirement(demand,demand.requirementId)"
        >
          <div class="container_order_content_item_todo">
            <!-- imageSrc :src="getImageSrc(demand)" -->
            <!-- <img :src="demand?.clientImageList.length == 0 ? goodimag : demand?.clientImageList[0]?.imageUrl" alt=""> -->
            <img
              :src="getImageSrc(demand)"
              alt=""
            >
            <div class="container_order_content_item_todo_right">
              <div class="container_order_content_item_todo_right_one">{{ demand.productName }}</div>
              <div class="container_order_content_item_todo_right_two">Quantity: {{ demand.quantity }}</div>
              <div class="container_order_content_item_todo_right_three">{{
                  demand.status === 1 ? "Submitted" :
                    "Pending"
                }}
              </div>
            </div>
          </div>
          <div class="container_order_content_item_list">
            <div
              class="container_order_content_item_list_item"
              v-for="quotation in demand.supplierQuotationList"
              :key="quotation.quotationId"
            >
              <div
                class="container_order_content_item_list_item_top"
                v-if="quotation.quotationId != null && quotation.status !== 0 && quotation.status !== 1"
              >
                <div
                  class="container_order_content_item_list_item_top_left"
                  @click.stop="openCo2(quotation)"
                >
                  <img
                    src="../../assets/images/home/carbonFootprint.png"
                    alt=""
                  >
                  <div class="container_order_content_item_list_item_top_left_co2">{{ thousands(quotation?.unitco) }}kg
                  </div>
                  <div class="container_order_content_item_list_item_top_left_text1">CO</div>
                  <div class="container_order_content_item_list_item_top_left_text2">2</div>
                </div>
                <div class="container_order_content_item_list_item_top_right">
                  <div class="container_order_content_item_list_item_top_right_text">{{
                      quotation.supplier?.supplierName ?
                        quotation.supplier?.supplierName : "ABC co."
                    }}
                  </div>
                  <img
                    src="../../assets/images/home/chevron_right.png"
                    alt=""
                  >
                </div>
                <!-- <div class="container_order_content_item_list_item_top_right" @click.stop="toQuotation(demand.requirementId, quotation.quotationId, 0)">
                  <div class="container_order_content_item_list_item_top_right_text">{{
                    quotation.supplier.supplierName ?
                    quotation.supplier.supplierName : "ABC co."
                  }}
                  </div>
                  <img src="../assets/images/home/chevron_right.png" alt="">
                </div> -->
              </div>
              <!-- <div class="container_order_content_item_list_line"
                v-if="quotation.quotationId != null && quotation.status !== 0 && quotation.status !== 1"></div> -->
            </div>
            <div
              class="container_order_content_item_list_tag"
              v-if="demand.supplierQuotationList && demand.supplierQuotationList.length > 3 && order.supplierQuotationList.indexOf(quotation) === order.supplierQuotationList.length - 1"
            >
              More
            </div>
            <div
              class="container_order_content_item_emp"
              v-if="!demand.supplierQuotationList || demand.supplierQuotationList.length === 0"
            >
              Waiting for quotation
            </div>
          </div>
        </div>
      </div>
      <!--      orderIdCheck=2-sample-order，orderIdCheck=3-active-order，orderIdCheck=4-completed-order  -->
      <div
        v-if="!isEmpty && orderIdCheck === 2"
        class="container_order_content SampleRequestBox"
      >
        <div
          class="container_order_content_item2"
          v-for="order in orderList"
          :key="order.orderId"
          @click="toOrderDetail(order.clientRequirementId, order.choosedSupplierQuotationList[0].quotationId, order.orderId)"
        >
          <div class="container_order_content_item_todo">
            <img
              :src="order?.clientImageList.length === 0 ? goodimag : order?.clientImageList[0]?.imageUrl"
              alt=""
            >
            <div class="container_order_content_item_todo_right">
              <div class="container_order_content_item_todo_right_one">{{ order.clientRequirement.productName }}</div>
              <div class="container_order_content_item_todo_right_two">Quantity: {{
                  order.clientRequirement.quantity
                }}
              </div>
              <!--                  <div class="container_order_content_item_todo_right_three">{{ order.orderType === 1 ? "In transit" : "Pending"}}</div>-->
              <div
                class="container_order_content_item_todo_right_three"
                v-if="orderIdCheck === 2"
              >{{
                  order.orderType ===
                  1
                    ? "In transit" : "Pending"
                }}
              </div>
            </div>
          </div>
          <!-- supplierQuotationList只能有一条，且quotation状态为选中状态-->
          <div class="container_order_content_item_content SampleRequest">
            <div
              class="container_order_content_item_list_item_top_left SampleRequest_left"
              @click.stop="openCo2(order.choosedSupplierQuotationList[0])"
            >
              <img
                src="../../assets/images/home/carbonFootprint.png"
                alt=""
              >
              <div class="container_order_content_item_list_item_top_left_co2">{{
                  thousands(order?.choosedSupplierQuotationList[0]?.unitco)
                }}kg
              </div>
              <div class="container_order_content_item_list_item_top_left_text1">CO</div>
              <div class="container_order_content_item_list_item_top_left_text2">2</div>
            </div>
            <div class="container_order_content_item_list_item_top_right SampleRequest_right">
              <div
                class="container_order_content_item_list_item_top_right_text"
                @click.stop="toQuotation(order.clientRequirementId, order.choosedSupplierQuotationList[0].quotationId, order.orderId)"
              >{{
                  order.supplierQuotationList.length === 0 ? "" : order.supplierQuotationList[0].supplierName ? order.supplierQuotationList[0].supplierName : ""
                }}
              </div>
              <img
                src="../../assets/images/home/chevron_right.png"
                alt=""
              >
            </div>
          </div>
          <div class="container_order_content_item_line"></div>
          <div
            class="container_order_content_item_tag"
            v-if="orderIdCheck === 2"
          >
            <div class="container_order_content_item_tag_left">
              <img
                src="../../assets/images/home/email_order.png"
                alt=""
              >
              <div
                class="container_order_content_item_tag_left_name"
                @click.stop="toSignFormalOrder(order.orderId, order.quotationId)"
              >
                Sign Formal Order
              </div>
            </div>
            <div class="container_order_content_item_tag_right">
              <img
                src="../../assets/images/home/car.png"
                alt=""
              >
              <div
                class="container_order_content_item_tag_right_name"
                @click.stop="openSample(order.orderId)"
              >Sample
                logistics
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      orderIdCheck === 3-active-order -->
      <div
        v-if="!isEmpty && orderIdCheck === 3"
        class="container_order_content"
      >
        <div
          class="container_order_content_item2"
          v-for="order in orderList"
          :key="order.orderId"
          @click="toOrderDetail(order.clientRequirementId, order.choosedSupplierQuotationList[0].quotationId, order.orderId)"
        >
          <div class="container_order_content_item_todo">
            <img
              :src="order?.clientImageList.length === 0 ? goodimag : order?.clientImageList[0]?.imageUrl"
              alt=""
            >
            <div class="container_order_content_item_todo_right">
              <div class="container_order_content_item_todo_right_one">{{ order.clientRequirement.productName }}</div>
              <div class="container_order_content_item_todo_right_two">Quantity: {{
                  order.clientRequirement.quantity
                }}
              </div>
              <!--                  <div class="container_order_content_item_todo_right_three">{{ order.orderType === 1 ? "In transit" : "Pending"}}</div>-->
              <div
                class="container_order_content_item_todo_right_three"
                v-if="orderIdCheck === 2"
              >{{
                  order.orderType ===
                  1
                    ? "In transit" : "Pending"
                }}
              </div>
            </div>
          </div>
          <!-- supplierQuotationList只能有一条，且quotation状态为选中状态-->
          <div class="container_order_content_item_content">
            <div
              class="container_order_content_item_list_item_top_left"
              @click.stop="openCo2(order.choosedSupplierQuotationList[0])"
            >
              <img
                src="../../assets/images/home/carbonFootprint.png"
                alt=""
              >
              <div class="container_order_content_item_list_item_top_left_co2">{{
                  thousands(order?.choosedSupplierQuotationList[0]?.unitco)
                }}kg
              </div>
              <div class="container_order_content_item_list_item_top_left_text1">CO</div>
              <div class="container_order_content_item_list_item_top_left_text2">2</div>
            </div>
            <div class="container_order_content_item_list_item_top_right">
              <div
                class="container_order_content_item_list_item_top_right_text"
                @click.stop="toQuotation(order.clientRequirementId, order.choosedSupplierQuotationList[0].quotationId, order.orderId)"
              >{{
                  order.supplierQuotationList.length === 0 ? "" : order.supplierQuotationList[0].supplierName ? order.supplierQuotationList[0].supplierName : ""
                }}
              </div>
              <img
                src="../../assets/images/home/chevron_right.png"
                alt=""
              >
            </div>
          </div>
          <div class="container_order_content_item_line"></div>
          <div
            class="container_order_content_item_tag2"
            v-if="orderIdCheck === 3"
          >
            <div
              class="container_order_content_item_tag2_name"
              @click.stop="toShippingDetail(order.orderId, order.choosedSupplierQuotationList[0].quotationId, order.orderTransportationInformation?.sealNumber)"
            >Carbon
              footprint tracking
            </div>
            <img
              class="container_order_content_item_tag2_to"
              src="../../assets/images/home/chevron_right.png"
              alt=""
            >
          </div>
        </div>
      </div>
      <!--      orderIdCheck=4-completed-order-->
      <div
        v-if="!isEmpty && orderIdCheck === 4"
        class="container_order_content completedOrder"
      >
        <!-- <div
          class="container_order_content_item2 completedOrder_item "
          v-if="orderIdCheck === 4"
        >
          <div class="completedOrder_item_one">
            Total Carbon Footprint
          </div>
          <div class="completedOrder_item_two">
            <div class="completedOrder_item_two_left">
              {{ thousands(finishTotalCo) }}
            </div>
            <div class="completedOrder_item_two_right">
              kg co2
            </div>
          </div>
        </div> -->
        <div
          class="container_order_content_item2 completedOrder_item2"
          v-for="order in orderList"
          :key="order.orderId"
          @click="toOrderFinish(order)"
        >
          <div class="container_order_content_item_todo">
            <img
              :src="order?.clientImageList.length === 0 ? goodimag : order?.clientImageList[0]?.imageUrl"
              alt=""
            >
            <div class="container_order_content_item_todo_right">
              <div class="container_order_content_item_todo_right_one">{{ order.clientRequirement.productName }}</div>
              <div class="container_order_content_item_todo_right_two">Quantity: {{
                  order.clientRequirement.quantity
                }}
              </div>
              <!--                  <div class="container_order_content_item_todo_right_three">{{ order.orderType === 1 ? "In transit" : "Pending"}}</div>-->
              <div
                class="container_order_content_item_todo_right_three"
                v-if="orderIdCheck === 2"
              >{{
                  order.orderType ===
                  1
                    ? "In transit" : "Pending"
                }}
              </div>
            </div>
          </div>
          <!-- supplierQuotationList只能有一条，且quotation状态为选中状态-->
          <div class="container_order_content_item_content">
            <div
              class="container_order_content_item_list_item_top_left"
              @click.stop="openCo2(order.choosedSupplierQuotationList[0])"
            >
              <img
                src="../../assets/images/home/carbonFootprint.png"
                alt=""
              >
              <div class="container_order_content_item_list_item_top_left_co2">{{
                  thousands(order.supplierQuotationList[0]?.unitco)
                }}kg
              </div>
              <div class="container_order_content_item_list_item_top_left_text1">CO</div>
              <div class="container_order_content_item_list_item_top_left_text2">2</div>
            </div>
            <div class="container_order_content_item_list_item_top_right">
              <div
                class="container_order_content_item_list_item_top_right_text"
                @click.stop="toOrderFinish(order)"
              >{{
                  order.supplierQuotationList.length === 0 ? "" : order.supplierQuotationList[0].supplierName ? order.supplierQuotationList[0].supplierName : ""
                }}
              </div>
              <img
                src="../../assets/images/home/chevron_right.png"
                alt=""
              >
            </div>
          </div>
          <div class="container_order_content_item_line completedOrder_item2_line"></div>
        </div>
      </div>

      <div
        class="container_order_page"
        v-if="orderIdCheck === 1"
      >
        <pagination
          v-show="demandTotal > 0"
          :total="demandTotal"
          layout="total,  prev, pager, next, jumper"
          v-model:page="queryDemand.pageNum"
          v-model:limit="queryDemand.pageSize"
          @pagination="getOrderList(orderIdCheck)"
          :auto-scroll="false"
        />
      </div>

      <div
        class="container_order_page"
        v-if="orderIdCheck !== 1"
      >
        <pagination
          v-show="orderTotal > 0"
          :total="orderTotal"
          layout="total,  prev, pager, next, jumper"
          v-model:page="queryOrder.pageNum"
          v-model:limit="queryOrder.pageSize"
          @pagination="getOrderList(orderIdCheck)"
          :auto-scroll="false"
        />
      </div>
    </div>
    <Foot/>

  </div>
  <!-- 查看碳值 -->
  <el-dialog
    v-model="showCo2"
    width="40%"
    :before-close="handleCloseCo2"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <img
          style="width: 16px;height: 16px"
          src="../../assets/images/home/carbonFootprint.png"
          alt=""
        >
        <div class="my-header_co2">{{ thousands(quoEntity.totalco) }}kg</div>
        <div class="my-header_unit">Co2</div>
      </div>
    </template>
    <div class="dialogCo2">
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Material</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">{{ thousands(quoEntity.materialCo) }}kg</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Material</div>
        <img
          src="../../assets/images/home/toRight.png"
          alt=""
        >
        <div class="dialogCo2_item_two">Factory</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">{{ thousands(quoEntity.materialCo) }}kg</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Factory</div>
        <img
          src="../../assets/images/home/toRight.png"
          alt=""
        >
        <div class="dialogCo2_item_two">{{ quoEntity.port }}</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">{{ thousands(quoEntity.factoryPortCo) }}kg</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">{{ quoEntity.port }}</div>
        <img
          src="../../assets/images/home/toRight.png"
          alt=""
        >
        <div class="dialogCo2_item_two">Dublin Airport</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">{{ thousands(quoEntity.internationalTransCo) }}kg</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Dublin Airport</div>
        <img
          src="../../assets/images/home/toRight.png"
          alt=""
        >
        <div class="dialogCo2_item_two">Home</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">{{ thousands(quoEntity.requireCo) }}kg</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
    </div>
  </el-dialog>
  <!-- 查看样品单物流情况 -->
  <el-dialog
    v-model="showSample"
    width="50%"
    :before-close="handleCloseSample"
  >
    <div class="dialogSample">
      <div
        class="dialogSample_item"
        v-for="item in sampleEntity"
        :key="item.id"
      >
        <div class="dialogSample_item_one">{{ item.AcceptStation }}</div>
        <div class="dialogSample_item_two">{{ item.AcceptTime }}</div>
        <div class="dialogSample_item_three">{{ item.Location }}</div>
        <div class="dialogSample_item_dashed" />
      </div>
    </div>
  </el-dialog>
</template>

<script setup name="CustomerOrder">
import { reactive, ref, toRefs } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import Pagination from '@/components/Pagination'
import { ElMessage } from 'element-plus'
import { queryClientOrderList, querySampleInfo } from '@/api/order'
import { thousands } from '@/utils/thousands'
// 引入的页面组件
import Foot from '@/components/Foot.vue'
import { queryDemandList } from '@/api/demand'

const router = useRouter()
const goodimag = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png')
const buttonList = ref([{ id: 3, name: 'Active Orders', active: false }, { id: 1, name: 'Order Submitted', active: true }, {
  id: 2,
  name: 'Sample Request',
  active: false
}, { id: 4, name: 'Completed Orders', active: false }])

const orderIdCheck = ref(1)

const imageUrl = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png')

const getImageSrc = (demand) => {
  if (demand.clientImageList.length === 0) {
    return goodimag.value
  } else {
    const imageUrl1 = demand.clientImageList[0].imageUrl || ''
    if (imageUrl1.toLowerCase().endsWith('.pdf')) {
      return goodimag.value
    } else {
      return imageUrl1
    }
  }
}

// 订单1
const queryDemand = ref()
queryDemand.value = { pageSize: 20, pageNum: 1 }
const demandTotal = ref(1)
const demandList = ref([])
const onLoadDemandList = async () => {
  demandList.value = []
  const result = await queryDemandList(queryDemand.value)
  if (result?.code === 200) {
    demandList.value = result?.data.rows
    demandTotal.value = result.data.total
    if (result.data.total > 0) {
      isEmpty.value = false
    } else {
      isEmpty.value = true
    }
  }
}
onLoadDemandList()

// 订单 2 3 4
const isEmpty = ref(false)

const orderList = ref([])
const orderTotal = ref('1')
const finishTotalCo = ref()

const queryOrder = ref()
queryOrder.value = {
  pageSize: 20,
  pageNum: 1,
  orderTypes: [1],
  orderStatus: '',
  type: 1
}
const onLoadOrderList = async () => {
  orderList.value = []
  const result = await queryClientOrderList(queryOrder.value)
  if (result?.code === 200) {
    orderList.value = result?.data.rows
    orderTotal.value = result.data.total
    console.log('orderlist.value s id:' + orderList.value)
    if (result.data.total > 0) {
      finishTotalCo.value = 0
      if (queryOrder.value.type == 4) {
        for (const e of orderList.value) {
          const s = e.choosedSupplierQuotationList[0]
          finishTotalCo.value += s.totalco
        }
      }
      isEmpty.value = false
    } else {
      isEmpty.value = true
    }
  }
}

const getOrderList = async (id) => {
  orderIdCheck.value = id
  for (const o of buttonList.value) {
    if (o.id === id) {
      o.active = true
    } else {
      o.active = false
    }
  }
  switch (orderIdCheck.value) {
  case 1:
    onLoadDemandList()
    break
  case 2:
    queryOrder.value.orderStatus = ''
    queryOrder.value.orderTypes = [1]
    queryOrder.value.type = 2
    onLoadOrderList()
    break
  case 3:
    queryOrder.value.orderStatus = ''
    queryOrder.value.orderTypes = [2, 3]
    queryOrder.value.type = 3
    onLoadOrderList()
    break
  case 4:
    queryOrder.value.orderStatus = 9
    queryOrder.value.orderTypes = [1, 2, 3]
    queryOrder.value.type = ''
    onLoadOrderList()
    break
  }
}

const showSample = ref(false)
const sampleEntity = ref([])
const openSample = async (orderId) => {
  // 去查询物流
  const result = await querySampleInfo({ orderId: orderId })
  if (result.code === 200) {
    if (result.data.Success === true && result.data.State !== '0') {
      sampleEntity.value = result.data.Traces
      showSample.value = true
    } else {
      ElMessage.message(result.data.Reason)
    }
  }
}

const showCo2 = ref(false)
const quoEntity = ref({})
const openCo2 = async (quo) => {
  quoEntity.value = quo
  showCo2.value = true
}

const toQuotation = (requirementId, quotationId, orderId) => {
  console.log('toQuotation')
  router.push({
    path: 'quotationDetail',
    query: {
      requirementId: requirementId,
      quotationId: quotationId,
      orderId: orderId
    }
  })
}

const toSignFormalOrder = (orderId, quotationId) => {
  console.log('toQuotation')
  router.push({
    path: 'quotation',
    query: {
      orderId: orderId,
      qid: quotationId
    }
  })
}

const toRequirement = (demand, requirementId) => {
  console.log('toRequirement')
  router.push({
    path: 'quotationDetails',
    query: {
      requirementId: requirementId
    }
  })
  // if (!demand.supplierQuotationList || demand.supplierQuotationList.length === 0) {
  //   router.push({
  //     path: 'quotationDetail',
  //     query: {
  //       requirementId: requirementId
  //     }
  //   })
  // } else {

  // }
}

const toOrderDetail = (requirementId, quotationId, orderId) => {
  console.log('toOrderDetail')
  router.push({
    path: 'quotationDetail',
    query: {
      requirementId: requirementId,
      quotationId: quotationId,
      orderId: orderId
    }
  })
}

const toOrderFinish = (order) => {
  console.log('toOrderFinish')
  router.push({
    path: 'officialOrderDetail',
    query: {
      requirementId: order.requirementId,
      quotationId: order.quotationId,
      orderId: order.orderId
    }
  })
}

const toShippingDetail = (orderId, quotationId, containerNumber) => {
  console.log('toShippingDetail')
  router.push({
    path: 'shipping',
    query: {
      orderId: orderId,
      qid: quotationId,
      containerNumber: containerNumber
    }
  })
}



</script>

<style lang="scss" scoped>
.my-header {
  height: 80px;
  line-height: 80px;
  border-radius: 8px 8px 0px 0px;
  background-color: rgba(248, 248, 248, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;

  &_co2 {
    margin-left: 10px;
    line-height: 80px;
    color: rgba(39, 174, 96, 1);
    font-size: 20px;
    text-align: left;
    font-weight: bold;
  }

  &_unit {
    margin-left: 10px;
    line-height: 20px;
    color: rgba(39, 174, 96, 0.8);
    font-size: 20px;
    text-align: left;
  }
}
.affix-add {
  position: fixed;
  top: 170px;
  right: 40px;
  span{
    font-weight: bolder;
    font-size: 16px;
  }
  &:hover {
    cursor: pointer;
  }
}
.dialogSample {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 16px 0;

  &_item {
    width: 80%;
    margin-bottom: 43px;
    display: flex;
    flex-direction: column;
    text-align: left;

    &_one {
      line-height: 22px;
      color: rgba(24, 24, 24, 1);
      font-size: 20px;
      text-align: left;
    }

    &_two {
      margin-top: 10px;
      line-height: 22px;
      color: rgba(24, 24, 24, 1);
      font-size: 20px;
      text-align: left;
    }

    &_three {
      line-height: 22px;
      color: rgba(39, 174, 96, 1);
      font-size: 20px;
      text-align: right;
      font-weight: bold;
    }

    &_dashed {
      margin-left: 10px;
      margin-right: 10px;
      width: 100px;
      border-top: 2px dashed var(--el-border-color);
    }
  }
}

.dialogCo2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 16px 0;

  &_item {
    width: 80%;
    margin-bottom: 43px;
    display: flex;
    align-items: center;

    img {
      flex: 1;
      margin-left: 15px;
      margin-right: 15px;
      width: 16px;
      height: 16px;
    }

    &_one {
      flex: 2;
      line-height: 22px;
      color: rgba(24, 24, 24, 1);
      font-size: 20px;
      text-align: left;
    }

    &_two {
      flex: 2;
      line-height: 22px;
      color: rgba(24, 24, 24, 1);
      font-size: 20px;
      text-align: left;
    }

    &_three {
      flex: 1;
      line-height: 22px;
      color: rgba(39, 174, 96, 1);
      font-size: 20px;
      text-align: right;
      font-weight: bold;
    }

    &_four {
      flex: 1;
      margin-left: 10px;
      line-height: 22px;
      color: rgba(36, 89, 2, 0.5);
      font-size: 16px;
      text-align: left;
    }

    &_dashed {
      flex: 5;
      margin-left: 10px;
      margin-right: 10px;
      width: 100px;
      border-top: 2px dashed var(--el-border-color);
    }
  }
}
.container {
  position: relative;
  // width: 100%;
  // height: 100vh;
  background-color: #ffffff;
  // display: flex;
  // flex-direction: column;

  &_order {
    margin-bottom: 60px;

    &_page {
      background-color: #f5f5f5;
      height: 60px;
      text-align: center;
      display: inline-block;
    }

    &_top {
      width: 1600px;
      margin: 40px auto;
      display: flex;

      &_text {
        flex: 1;
        line-height: 36px;
        color: rgba(36, 89, 2, 1);
        font-size: 28px;
        text-align: left;
        font-weight: bold;
      }

      &_buttons {
        display: flex;

        &_item1 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 1);
          text-align: center;
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
        }

        &_item2 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 0.06);
          text-align: center;
          color: rgba(39, 174, 96, 1);
          font-size: 20px;
        }
      }
    }

    &_content {
      width: 1600px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      // width: 1200px;
      // margin: 0 auto;
      // column-count: 3;
      // column-gap: 15px;
      margin-bottom: 15px;

      &_item {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        width: 388px;
        margin-bottom: 15px;
        border-radius: 10px;
        margin-left: 10px;

        // break-inside: avoid-column;
        &:nth-child(3n) {
          margin-right: 0;
        }

        &_todo {
          height: 80px;
          padding: 30px 30px 0 30px;
          line-height: 20px;
          border-radius: 10px 10px 0px 0px;
          background: linear-gradient(
              180deg,
              rgba(39, 174, 96, 0.1) 0%,
              rgba(39, 174, 96, 0) 100%
          );
          display: flex;

          img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            border: 1px solid rgba(39, 174, 96, 0.1);
          }

          &_right {
            margin-left: 15px;
            height: 80px;
            display: flex;
            flex-direction: column;

            &_one {
              color: rgba(24, 24, 24, 1);
              font-size: 18px;
              font-weight: 600;
              text-align: left;
              width: 220px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &_two {
              margin-top: 6px;
              color: rgba(173, 173, 173, 1);
              font-size: 20px;
              text-align: left;
            }

            &_three {
              margin-top: 10px;
              padding: 2px 4px 2px 4px;
              border-radius: 2px;
              background-color: rgba(39, 174, 96, 0.06);
              color: rgba(36, 89, 2, 0.6);
              font-size: 20px;
              text-align: center;
            }
          }
        }

        &_emp {
          width: 100%;
          margin-top: 20px;
          line-height: 22px;
          color: rgba(36, 89, 2, 0.8);
          font-size: 20px;
          text-align: center;
        }

        &_list {
          padding: 10px 30px 20px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &_item {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-top: 1px solid rgba(240, 240, 240, 1);

            &:first-child {
              border: none;
            }

            &_top {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 20px 0;

              &_left {
                flex: 1;
                display: flex;

                img {
                  width: 16px;
                  height: 16px;
                }

                &_co2 {
                  margin-left: 10px;
                  line-height: 22px;
                  color: rgba(39, 174, 96, 1);
                  font-size: 20px;
                  text-align: left;
                  font-weight: bold;
                }

                &_text1 {
                  margin-left: 5px;
                  height: 16px;
                  color: rgba(36, 89, 2, 0.5);
                  font-size: 16px;
                  text-align: left;
                }

                &_text2 {
                  height: 12px;
                  color: rgba(36, 89, 2, 0.5);
                  font-size: 10px;
                  text-align: left;
                }
              }

              &_right {
                display: flex;

                &_text {
                  line-height: 22px;
                  color: rgba(36, 89, 2, 0.6);
                  font-size: 20px;
                  text-align: right;
                  width: 150px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          &_line {
            margin-top: 18px;
            margin-bottom: 18px;
            width: 100%;
            height: 1px;
            background-color: rgba(240, 240, 240, 1);
            text-align: center;
          }

          &_tag {
            height: 12px;
            color: rgba(173, 173, 173, 1);
            font-size: 20px;
            text-align: left;
            font-family: Helvetica-regular;
          }
        }

        &_content {
          padding: 40px 30px 20px 30px;
          display: flex;
        }

        &_line {
          // margin-top: 0px;
          margin-bottom: 18px;
          width: 100%;
          height: 1px;
          line-height: 20px;
          background-color: rgba(240, 240, 240, 1);
          text-align: center;
        }

        &_tag {
          display: flex;
          align-items: center;
          padding-bottom: 20px;

          &_left {
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            margin-left: 10px;

            img {
              width: 16px;
              height: 16px;
            }

            &_name {
              margin-left: 5px;
              line-height: 22px;
              color: rgba(36, 89, 2, 1);
              font-size: 20px;
              text-align: right;
              font-family: Helvetica-regular;
            }
          }

          &_right {
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;

            img {
              width: 16px;
              height: 16px;
            }

            &_name {
              margin-left: 5px;
              line-height: 22px;
              color: rgba(36, 89, 2, 1);
              font-size: 20px;
              text-align: right;
              font-family: Helvetica-regular;
            }
          }
        }

        &_tag2 {
          margin: 0 auto;
          display: flex;
          text-align: center;
          align-items: center;
          padding-bottom: 20px;

          &_name {
            line-height: 22px;
            color: rgba(36, 89, 2, 1);
            font-size: 16px;
            text-align: right;
            font-family: Helvetica-regular;
          }

          &_to {
            width: 16px;
            height: 16px;
          }
        }
      }

      &_item2 {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        // border: 1px solid rgba(255, 255, 255, 1);
        // margin: 10px;
        // width: calc(33% - 20px);
        // height: 250px;
        width: 388px;
        margin-bottom: 15px;
        border-radius: 10px;
        break-inside: avoid-column;
        cursor: pointer;
        margin-left: 10px;

        // break-inside: avoid-column;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      &_item3 {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        width: 388px;
        height: 200px;
        break-inside: avoid-column;
        margin-bottom: 15px;
      }
    }

    &_empty {
      width: 300px;
      height: 300px;
      margin: 40px 450px 40px 450px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // completedOrder
  .completedOrder {
    &_item2 {
      height: 200px;

      &_line {
        display: none;
      }
    }

    &_item {
      height: 200px;

      &_one {
        margin: 75px 60px 0px 75px;
        height: 12px;
        color: rgba(173, 173, 173, 1);
        font-size: 16px;
        text-align: left;
        font-family: Helvetica-regular;
      }

      &_two {
        margin: 10px 60px 0px 75px;
        display: flex;
        align-items: center;

        &_left {
          line-height: 24px;
          color: rgba(39, 174, 96, 1);
          font-size: 24px;
          text-align: left;
          font-family: Helvetica-bold;
        }

        &_right {
          margin-left: 5px;
          line-height: 22px;
          color: rgba(39, 174, 96, 1);
          font-size: 16px;
          text-align: left;
          font-family: Helvetica-regular;
        }
      }
    }
  }

}
</style>
