export function thousands (num) {
  console.log('num', num)
  console.log('typeop', typeof num)
  if (typeof num === 'number') {
    if (!num) {
      return 0
    }
    if (num === 0) {
      return 0
    }
    var kg = num / 1000
    var str = kg.toFixed(2).toString()
    var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, '$1,')
  } else if (typeof num === 'string') {
    if (!num) {
      return 0
    }
    if (num === 0) {
      return 0
    }
    var num2 = Number(num)
    var kg2 = num2 / 1000
    var str2 = kg2.toFixed(2).toString()
    var reg2 = str2.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str2.replace(reg2, '$1,')
  }
}
