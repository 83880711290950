import request from '@/utils/request'

export function queryClientOrderList (params) {
  return request({
    url: '/api/orderForGoods/client/list',
    method: 'get',
    params
  })
}

export function querySupplierOrderList (params) {
  return request({
    url: '/api/orderForGoods/supplier/list',
    method: 'get',
    params
  })
}

export function getOrderDetail (orderId) {
  return request({
    url: '/api/orderForGoods/' + orderId,
    method: 'get'
  })
}

export function downloadDocs (orderId) {
  return request({
    url: '/api/orderForGoods/exportDoc/' + orderId,
    method: 'get'
  })
}

export function getOrderByQuotationId (quotationId, orderId) {
  return request({
    url: '/api/orderForGoods/getOrderByQuotationId/' + quotationId + '/' + orderId,
    method: 'get'
  })
}

export function getOrderTimeLines (orderId) {
  return request({
    url: '/api/orderTimeLine/getOrderTimeLine/' + orderId,
    method: 'get'
  })
}

export function allowOrRefuseQuo (data) {
  return request({
    url: '/api/orderForGoods/allowOrRefuseQuo',
    method: 'post',
    data
  })
}

export function updateOrder (data) {
  return request({
    url: '/api/orderForGoods/updateOrder',
    method: 'post',
    data
  })
}

export function signFormalOrder (data) {
  return request({
    url: '/api/orderForGoods/signFormalOrder',
    method: 'post',
    data
  })
}

export function querySampleInfo (params) {
  return request({
    url: '/api/common/querySampleInfo',
    method: 'get',
    params
  })
}

export function getOrderTimeLineAll (orderId) {
  return request({
    url: '/api/orderTimeLine/getOrderTimeLineAll/' + orderId,
    method: 'get'
  })
}
